import React, { useEffect, useState } from "react";
import Timer from "./components/Timer";
import Participant from "./Participant";

const Room = ({
  connectionQuality,
  room,
  handleLogout,
  handleVideoTrackStop,
  handleVideoTrack,
  handleAudioTrackStop,
  handleAudioTrack,
  patient,
  appointment,
}) => {
  const [participants, setParticipants] = useState([]);
  const [isVideo, setIsVideo] = useState(true);
  const [isAudio, setIsAudio] = useState(true);

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };

    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    room.participants.forEach(participantConnected);

    return () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
    };
  }, [room]);

  const remoteParticipants = participants.map((participant, index) => (
    <Participant
      key={participant.sid}
      participant={participant}
      remote={true}
    />
  ));
  const activedVideo = () => {
    if (isVideo) {
      setIsVideo(false);
      handleVideoTrackStop();
    } else {
      setIsVideo(true);
      handleVideoTrack();
    }
  };
  const activedAudio = () => {
    if (isAudio) {
      setIsAudio(false);
      handleAudioTrackStop();
    } else {
      setIsVideo(true);
      setIsAudio(true);
      handleAudioTrack();
    }
  };

  const disabledCamera = () => {
    if (isVideo) {
      room.localParticipant.videoTracks.forEach((publication) => {
        publication.track.disable();
      });
      setIsVideo(false);
    } else {
      room.localParticipant.videoTracks.forEach((publication) => {
        publication.track.enable();
      });
      setIsVideo(true);
    }
  };

  return (
    <div className="room">
      <div className="room-name">
        <h4 style={{ marginBottom: "0.5rem" }}>
          Tu conexión es: {connectionQuality}
        </h4>
        <h3 style={{ marginBottom: "0.5rem" }}>{patient}</h3>
        <h4
          style={{
            marginBottom: "0.5rem",
            maxWidth: "350px",
            fontWeight: "200",
          }}
        >
          {appointment}
        </h4>
        <Timer />
      </div>
      <div className="buttons-area">
        <div className="buttons-container">
          <button
            className={`button-${isAudio ? "normal" : "muted"}`}
            onClick={() => activedAudio(isAudio)}
          >
            <img src={`mic${isAudio ? "" : "-muted"}.svg`} alt="end call" />
          </button>

          <button className="button-end" onClick={handleLogout}>
            <img src="end-call.svg" alt="end call" />
          </button>

          <button
            className={`button-${isVideo ? "normal" : "muted"}`}
            onClick={() => disabledCamera()}
          >
            <img src={`camera${isVideo ? "" : "-muted"}.svg`} alt="end call" />
          </button>
        </div>
      </div>
      <div className="local-participant">
        {room ? (
          <Participant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
            name="Tú"
            remote={false}
          />
        ) : (
          ""
        )}
      </div>
      <div className="remote-participants">
        {remoteParticipants.length === 0 ? (
          <h1 className="waiting">
            Esperando participante, por favor espere un momento...
          </h1>
        ) : (
          remoteParticipants
        )}
      </div>
    </div>
  );
};

export default Room;

import React, { useEffect, useState } from "react";

const Timer = () => {
  const [timer, setTimer] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const secondsToString = (seconds) => {
    var minute = Math.floor((seconds / 60) % 60);
    minute = minute < 10 ? "0" + minute : minute;
    var second = seconds % 60;
    second = second < 10 ? "0" + second : second;
    return minute + ":" + second;
  };

  return <span>{secondsToString(timer)} min</span>;
};

export default Timer;

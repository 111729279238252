import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyAysU3cfGKC1Ogii173gO1zNaAQOsizTJQ",
  authDomain: "daisies-70154.firebaseapp.com",
  projectId: "daisies-70154",
  storageBucket: "daisies-70154.appspot.com",
  messagingSenderId: "772742382225",
  appId: "1:772742382225:web:47643d98a6f8b0135f3530",
  measurementId: "G-1XNTTQMZCS",
};

const app = initializeApp(firebaseConfig);
export { firebaseConfig, app };

import React, { useState, useEffect, useRef } from "react";

const Participant = (props) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(props.participant.videoTracks));
    setAudioTracks(trackpubsToTracks(props.participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    props.participant.on("trackSubscribed", trackSubscribed);
    props.participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      props.participant.removeAllListeners();
    };
  }, [props]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <>
      {!props.remote ? (
        <div className="participant">
          <h3 className="name">{props.name}</h3>
          <video ref={videoRef && videoRef} autoPlay={false} />
          <audio ref={audioRef && audioRef} autoPlay={true} muted={false} />
        </div>
      ) : (
        <div className="participant-remote">
          <h3 className="name">{props.name}</h3>
          <video ref={videoRef && videoRef} autoPlay={false} />
          <audio ref={audioRef && audioRef} autoPlay={true} muted={false} />
        </div>
      )}
    </>
  );
};

export default Participant;

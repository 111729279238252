import React, { useState, useCallback, useEffect } from "react";
import Video from "twilio-video";
import Room from "./Room";
import { app } from "./firebase";

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import axios from "axios";

const VideoChat = () => {
  const [roomName, setRoomName] = useState("");
  const [room, setRoom] = useState(null);
  const [connecting, setConnecting] = useState(false);
  const [connectionQuality, setConnectionQuality] = useState(null)
  const [appointments, setAppointments] = useState({});
  const [user, setUser] = useState({});
  const [lock, setScreenLock] = useState();

  function isScreenLockSupported() {
    return "wakeLock" in navigator;
  }
  const getScreenLock = async () => {
    if (isScreenLockSupported()) {
      let screenLock;
      try {
        screenLock = await navigator.wakeLock.request("screen");
      } catch (err) {
        console.log(err.name, err.message);
      }
      setScreenLock(screenLock);
    }
  };
  useEffect(() => {
    getScreenLock();
  }, []);

  const getAppointment = async (id) => {
    const auth = getAuth();

    signInWithEmailAndPassword(
      auth,
      process.env.REACT_APP_ACCESS_FIREBASE_EMAIL,
      process.env.REACT_APP_ACCESS_FIREBASE_PASS
    )
      .then(({ user }) => {
        const { accessToken } = user;
        console.log(accessToken);
        axios
          .get(
            `${process.env.REACT_APP_ACCESS_API}appointment/appointmentById/${id}`,
            {
              headers: {
                Authorization: accessToken,
                "Access-Control-Allow-Origin": true,
              },
            }
          )

          .then((json) => {
            console.log(json);
            setUser(json.data.user);
            setAppointments(json.data);
          })
          .catch((e) => console.log(e));
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, "=>", errorMessage);
      });
  };

  const handleSubmit = async (roomid) => {
    setConnecting(true);
    setRoomName(roomid);

    Video.connect(roomid, {
      name: "Dra.",
      networkQuality: {
        local: 3,
        remote: 1
      },
      preferredVideoCodecs: [
        { codec: 'VP8', simulcast: true }
      ]
    })
      .then((room) => {
        room.localParticipant.setNetworkQualityConfiguration({
          local: 3,
          remote: 1
        });
        setConnecting(false);
        setRoom(room);
        getAppointment(room?.name);
      })
      .catch((err) => {
        console.error(err);
        setConnecting(false);
      });
  };

  const handleLogout = useCallback(() => {
    setRoom((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub) => {
          trackPub.track.stop();
        });
        prevRoom.disconnect();
        lock.release();
      }
      return null;
    });
    window.close();
  }, []);

  const handleVideoTrackStop = useCallback((res) => {
    setRoom((prevRoom) => {
      prevRoom.localParticipant.videoTracks.forEach((trackPub) => {
        trackPub.unpublish();
      });
      return prevRoom;
    });
  }, []);

  const handleAudioTrackStop = useCallback((res) => {
    setRoom((prevRoom) => {
      prevRoom.localParticipant.audioTracks.forEach((trackPub) => {
        trackPub.track.stop();
        trackPub.unpublish();
      });
      return prevRoom;
    });
  }, []);

  const handleVideoTrack = useCallback((res) => {
    console.log(res);
    Video.createLocalVideoTrack().then(function (localTrack) {
      setRoom((prevRoom) => {
        prevRoom.localParticipant.publishTrack(localTrack);
        return prevRoom;
      });
    });
  }, []);

  const handleAudioTrack = useCallback((res) => {
    Video.createLocalAudioTrack().then(function (localTrack) {
      setRoom((prevRoom) => {
        prevRoom.localParticipant.publishTrack(localTrack);
        return prevRoom;
      });
    });
  }, []);

  function printNetworkQualityStats(networkQualityLevel, networkQualityStats) {
    // Print in console the networkQualityLevel using bars
    console.log(networkQualityLevel)
    switch (networkQualityLevel) {
      case 1:
        setConnectionQuality('Pésima');
        break;
      case 2:
        setConnectionQuality('Muy mala');
        break;
      case 3:
        setConnectionQuality('Mala');
        break;
      case 4:
        setConnectionQuality('Regular');
        break;
      case 5:
        setConnectionQuality('Buena');
        break;

    }

    if (networkQualityStats) {
      // Print in console the networkQualityStats, which is non-null only if Network Quality
      // verbosity is 2 (moderate) or greater
      console.log('Network Quality statistics:', networkQualityStats);
    }
  }

  useEffect(() => {
    if (room) {

      printNetworkQualityStats(room.localParticipant.networkQualityLevel, room.localParticipant.networkQualityStats);
      room.localParticipant.on('networkQualityLevelChanged', printNetworkQualityStats);
    }
  }, [room])


  const urlQuery = new URLSearchParams(document.location.search);

  useEffect(() => {
    if (!connecting) {
      handleSubmit(urlQuery.get("tenant"));
    }
    if (room) {
      const tidyUp = (event) => {
        if (event.persisted) {
          return;
        }
        if (room) {
          handleLogout();
        }
      };
      window.addEventListener("pagehide", tidyUp);
      window.addEventListener("beforeunload", tidyUp);
      return () => {
        window.removeEventListener("pagehide", tidyUp);
        window.removeEventListener("beforeunload", tidyUp);
      };
    }
  }, []);

  return (
    <>
      {room && user && appointments && (
        <Room
          connectionQuality={connectionQuality}
          roomName={roomName}
          room={room}
          patient={`${user?.name} ${user?.lastName}`}
          appointment={appointments?.reason}
          handleLogout={handleLogout}
          handleVideoTrackStop={handleVideoTrackStop}
          handleVideoTrack={handleVideoTrack}
          handleAudioTrack={handleAudioTrack}
          handleAudioTrackStop={handleAudioTrackStop}
          duration={appointments?.duration}
        />
      )}
    </>
  );
};

export default VideoChat;
